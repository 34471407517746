import React, { useState, useEffect, useRef } from "react";

//components

//utils
// import { keepTheme } from "./utils/theme";

//local styles
import styles from "./App.module.css";

function App() {
  return (
    <>
      <div
        className={`${styles.container} h-100 w-100 flex flex-col flex-jc-sb flex-ai-fs font-heading-1 padding`}
      >
        <div className={``}>
          collabi,
          <br />
          not just any other art studio.
        </div>
        <a
          href="https://airtable.com/appPdY4os702JN0l7/shrCwKJAdy94QP4W6"
          target="_blank"
          classname="link"
        >
          enquire
        </a>
      </div>
    </>
  );
}

export default App;
